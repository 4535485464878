import { Config } from "../config/config";
import { HttpClient } from "./http";

export class FeedBackService {
  public static async getFeedBack() {
    return HttpClient.get(`/common/feedback/getContest`)
  }
  public static async addFeedBack(data :any) {
    return HttpClient.post(`/common/feedback/addContest`, data)
  }
  public static async addFeedBacktoCrm(data :any) {
    return HttpClient.post(`/feedback`, data,{
      baseURL:Config.DEV_CRM,
      headers:{
        "Authorization" : "App eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjJmYTg4Y2I1LWZlMTktNGM2OS",                                                               "Migration-Authorization": "12435667-jK67G-jK67G-Ok78H-rtBH67KLLM67"
      }
    })
  }
}
