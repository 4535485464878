export const RegxUtil = {
    OnlyStrings: /[a-zA-Z]/gi,
    CheckSpecialCharacters: /[^\w\s]/gi,
    HasNumbers: /[0-9]/gi,
    MobileNumber: /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
    UserName: /^[a-zA-Z]+$/,
    Mobiles: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
    Email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    password: /^[a-zA-Z0-9_-]{3,23}$/,
    Pincode: '^[1-9]{1}[0-9]{2}\\s{0,1}[0-9]{3}$',
    Title: /[^ ]+/,
    TextArea: /^[0-9a-zA-Z]{3,1024}$/,
    minChar: /^(?=^[^_]+_?[^_]+$)\w{3,20}$/,
    Pincodedata: /^\d{6}$/,
    images: /^\w+\.(gif|png|jpg|jpeg)$/,
    ReviewText: /^.{3,35}$/,
    Maximum: /^.{5,400}$/,
    minimum: /^\b\w+\b(?:.*?\b\w+\b){2}/,
    max: /^\s*([a-zA-Z]+\s*){1,3}$/,
    pin: /^[1-9][0-9]{5}$/,
    emobile: /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
    PasswordSize: /^(\w{5,15})$/,
    PasswordLength: /^[a-zA-Z0-9!@#$%^&*()<>?,.,;:"-'₩₹£€_/=÷×+`~{[}]{5,15}$/,
    ContactLength: /^.{10,}$/,
    minCharlength: /(.*[a-z]){3}/i,
    numberandAlphabets: /^([a-zA-Z0-9]+)$/
  }
  
  Object.freeze(RegxUtil)
  